<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Add UpComing Diplomas
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 60%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="12">
            <b-col md="12">
              <b-form-group
                label="Diploma Type"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Type"
                  rules="required"
                >
                  <v-select
                  data-testid="diploma-course-type"
                    v-model="addCourseForm.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="typeList"
                    :reduce="(val) => val.id"
                    @input="getCourseSchedule(addCourseForm.type)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="addCourseForm.type !== 'dedicated'"
              md="12"
            >
              <b-form-group
                label="Select Category"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="cate"
                  rules=""
                >
                  <v-select
                  data-testid="diploma-category"
                    v-model="addCourseForm.category_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="type"
                    :options="categoriesList"
                    :reduce="(val) => val.id"
                    @input="getCoursesByCategory(addCourseForm.category_id)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="addCourseForm.type == 'dedicated' && dedicated"
              md="12"
            >
              <b-form-group
                label="Select Diploma"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
                  <v-select
                    id="blog-edit-category"
                    v-model="addCourseForm.course"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="coursesList"

                    :reduce="(val) => val.id"
                  >
                    <template #no-options="{ search, searching, loading }">
                      No data ...
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="!dedicated"
              md="12"
            >
              <b-form-group
                label="Select Diploma"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
                  <v-select
                  data-testid="diploma-name"
                    v-model="addCourseForm.course"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="coursesList"
                    :reduce="(val) => val.id"
                    @input="getscheduleByCourseOnline('',addCourseForm.course)"
                  >
                    <template #no-options="{ search, searching, loading }">
                   Select Category First...
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="
                addCourseForm.type !== 'dedicated' &&
                  addCourseForm.type !== 'online'
              "
              md="12"
            >
              <b-form-group
                label="Select City"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
                  <v-select
                  data-testid="diploma-city"
                    v-model="addCourseForm.city"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="citiesList"
                    :reduce="(val) => val.id"
                    @input="
                      getscheduleByCourse(
                        addCourseForm.city,
                        addCourseForm.course
                      )
                    "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Select Schedule Date"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="schedule"
                  rules="required"
                >
                  <v-select
                  data-testid="diploma-date"
                    v-model="addCourseForm.schedule"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="date"
                    :options="scheduleList"
                    :reduce="(val) => val.id"
                  >
                    <template #no-options="{ search, searching, loading }">
                    Select City and Course ...
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="In Slider Order"
                label-for="blog-edit-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="In Slider Order"
                  rules="required"
                >
                  <b-form-input
                  data-testid="diploma-slider"
                    v-model="addCourseForm.slider"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
            data-testid="upcoming-submit-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,

  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,

  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

export default {
  setup(props, { emit }) {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('')
    const coursesList = ref([])
    const categoriesList = ref([])
    const scheduleList = ref([])
    const citiesList = ref([])
    const addCourseForm = reactive({
      category_id: '',
      type: '',
      course: '',
      city: '',
      schedule: '',
      slider: '',
    })
    const dedicated = ref(false)
    store.dispatch('categories/CategoryList').then(response => {
      categoriesList.value = response.data
    })
    const getCourseSchedule = id => {
      if (id == 'dedicated') {
        dedicated.value = true
        store.dispatch('homepageCourseLong/getScheduleDate').then(response => {
          scheduleList.value = response.data.data
        })
        store.dispatch('homepageCourseLong/getOtherCourse', { id }).then(response => {
          coursesList.value = response.data.data
        })
      }
      if (id == 'online') {
        dedicated.value = false
        store
          .dispatch('longcourses/getAllCoursesOnline')
          .then(response => {
            coursesList.value = response.data.data
          })
      }
      if (id == 'classic') {
        dedicated.value = false
        store
          .dispatch('longcourses/getAllClassical')
          .then(response => {
            coursesList.value = response.data.data
          })
      }
    }
    const getscheduleByCourse = (cityid, courseid) => {
      store
        .dispatch('homepageCourseLong/getscheduleByCourse', { cityid, courseid })
        .then(response => {
          scheduleList.value = response.data
        })
    }
    const getscheduleByCourseOnline = (cityid, courseid) => {
      if (addCourseForm.type == 'online') {
        store
          .dispatch('homepageCourseLong/getscheduleByCourse', { cityid, courseid })
          .then(response => {
            scheduleList.value = response.data
          })
      }
    }
    const typeList = [
      {
        name: 'Classic',
        id: 'classic',
      },
      {
        name: 'Online',
        id: 'online',
      },

      // {
      //   name: 'Dedicated',
      //   id: 'dedicated',
      // },
    ]

 
    const getCoursesByCategory = id => {
      let online = null
      // if (addCourseForm.type == 'online') {
      //   online = 1
      // } else {
      //   online = 0
      // }
      // store
      //   .dispatch('longcourses/getAllCoursesList', {
      //    "category_id": id,
      //   })
      //   .then(response => {
      //     coursesList.value = response.data
      //   })
        if (addCourseForm.type == 'online') {
        dedicated.value = false
        store
        .dispatch('longcourses/getCoursesByCategoryOnline', {

     
          "filter[category_id]": id,

        })
          .then(response => {
            coursesList.value = response.data
          })
      }
      if (addCourseForm.type == 'classic') {
        dedicated.value = false
        store
        .dispatch('longcourses/getCoursesByCategoryClassical', {

     
          "filter[category_id]": id,

        })
          .then(response => {
            coursesList.value = response.data
          })
      }
    }
    store.dispatch('cities/cititesList').then(response => {
      citiesList.value = response.data
    })
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('long_schedule_id', addCourseForm.schedule)
          // formData.append('type', addCourseForm.type)
          formData.append('in_slider_order', addCourseForm.slider)
          // formData.append("type", addCourseForm.type);
          // formData.append("category_id", addCourseForm.category_id);
          // formData.append("in_slider_order", addCourseForm.slider);
          // formData.append("schedule_id", addCourseForm.schedule);
          // formData.append("type", addCourseForm.type);

          store
            .dispatch('homepageCourseLong/addUpComingCourse', formData)
            .then(response => {
              addCourseForm.schedule = ''
              addCourseForm.type = ''
              addCourseForm.slider = ''
              addCourseForm.course = ''
              addCourseForm.category_id = ''
              addCourseForm.city = ''
              addCourseForm.slider = ''

              emit('add-course')
              Vue.swal({
                title: ' Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    return {
      refInputEl,
      dedicated,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      typeList,
      categoriesList,
      getCoursesByCategory,
      citiesList,
      getCourseSchedule,
      scheduleList,
      getscheduleByCourseOnline,
      getscheduleByCourse,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,

    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,

    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
